import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import * as icons from '../../icons/alertIcons';

const Alert = styled.div`
  margin: 1.5rem 0 1rem;
  border-radius: 4px;
  padding: 10px 12px 10px 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  & svg {
    align-self: center;
    line-height: 100%;
    width: 16px;
    height: 16px;
  }
  &.info {
    background-color: #cce5ff;
    border: 1px solid #b8daff;
  }
  &.success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  &.warning {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
  }
  &.danger {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
`;

const Text = styled.p`
  margin: 0 0 0 16px;
  width: fit-content;
  font-weight: 400;
`;

export const Information = ({ children, color, style }) => {
  const [filterIcon] = Object.entries(icons).filter((icon) => {
    return icon[0] === color ? icon[1] : null;
  });
  return (
    <Alert className={color} style={style}>
      {filterIcon[1]}
      <Text>{children}</Text>
    </Alert>
  );
};

Information.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Information.defaultProps = {
  style: undefined,
};
