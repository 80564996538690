import React from 'react';

import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import minTimeDuration from 'img/help/min-time-duration1.png';
import minTimeDuration2 from 'img/help/min-time-duration2.png';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <p>
        LiveSession gives you an option to choose the minimum length of the recorded sessions. In
        short, you can cut off all sessions that are shorter than the time you set in your
        website&apos;s settings.
      </p>

      <SubTitle>How does it work?</SubTitle>
      <p>
        For example, if you choose not to capture sessions shorter than 20 seconds, we won&apos;t
        save them. That means you won&apos;t see them on the sessions list. By default, recording
        starts right after the user visits the page. If he leaves it (i.e., by going from your home
        page to the pricing page) in less than 20 seconds or doesn&apos;t spend the required amount
        of time on any page, no session will be saved.
      </p>

      <Information color="info" style={{ margin: '32px 0' }}>
        Although by default, we start recording immediately after your user visits your website,
        sessions that don&apos;t meet the minimum time on page criterium won&apos;t lower your
        monthly sessions quota.
      </Information>

      <SubTitle>How to set up the minimum time on page?</SubTitle>
      <p>To start recording only sessions that meet the minimum time on page, do the following:</p>

      <ol>
        <li>
          <strong>Go to Settings</strong> &#8594;<strong> Websites</strong>. Find a website you
          would like to apply the minimum time on page to and click on “edit” on the right side:
          <Image src={minTimeDuration} alt="Pause recording" title="Go to settings" />
        </li>
        <li>
          In Settings, choose the &quot;Minimum time on page&quot; option and set the minimum
          session length you&apos;d like to track:
          <Image src={minTimeDuration2} lazy alt="Minimum tim" title="Pop-up window" />
        </li>
        <li>Click on the &quot;Save changes&quot; button.</li>
      </ol>
    </div>
  );
};

export const frontmatter = {
  metaTitle: 'What is minimum time on page?',
  metaDescription:
    'Minimum time on page is an option to choose the minimum length of the recorded sessions.',
  canonical: '/help/what-is-minimum-time-on-page',
};

const Wrapped = Article(Content);

export default () => <Wrapped section="Product" title={frontmatter.metaTitle} {...frontmatter} />;
